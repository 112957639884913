import { Helmet } from 'react-helmet-async';
import '../assets/css/fonts.css';
import '../assets/css/bootstrap/bootstrap.min.css';
import '../assets/css/style.scss';
import ContactSection from '../components/ContactSection';
import PortfolioSection from '../components/PortfolioSection';
import { Fade } from "react-awesome-reveal";

export default function Portfolio() {


  return (
    <>
      <Helmet>
        <title>Portfólio - LucasDesign</title>
        <meta name="title" content="LucasDesign" />
        <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
        <meta name="keywords" content="web developer,developer,website,web,custom" />
        <meta name="robots" content="index, follow" />
        <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
      </Helmet>
      <section className="section-home-hero">
        <div className="container-inner">
          <div className="row">
            <div className="col-12">
              <Fade triggerOnce duration={1000} direction="up">
                <h1>Moje portfólio</h1>
              </Fade>
            </div>
          </div>
        </div>

      </section>

      <Fade triggerOnce duration={1200} direction="up">
        <PortfolioSection />
      </Fade>

      <ContactSection />

    </>

  )
}