import Logo from '../logo.svg';
import { Link } from 'react-router-dom';

export default function Footer() {

    const currentYear = new Date().getFullYear();

    return (
        <footer>

            <div className="container">
                <div className="container-inner">
                    <div className="row gy-4">
                        <div className="col-sm-12 col-md-6">
                            <div className="footer-1">
                                <a href="/"><img src={Logo} alt="logo" /></a>
                                <p>Spolupracujme a vytvárajme spolu výnimočné webové zážitky!</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <h4>Sociálne siete</h4>
                            <ul className="vstack gap-2 align-items-start socials">
                                <li className="social">
                                    <a href="https://www.instagram.com/lucasdesign.sk/" target="_blank" rel="noopener noreferrer">Instagram</a>
                                </li>
                                <li className="social">
                                    <a href="https://www.linkedin.com/in/lucas-rim%C3%B3ci-818479217/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <h4>Informácie</h4>
                            <ul className="pages">
                                <li><a href="#work">Portfólio</a></li>
                                <li><a href="#services">O mne</a></li>
                                <li><a href="#services">Služby</a></li>
                                <li><Link to={`/ochrana-osobnych-udajov`}>Ochrana osobných údajov</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="row gy-4">
                            <div className="col-sm-12 col-md-6">
                                <p>© <span id="year">{currentYear}</span> LucasDesign - All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </footer>
    )
}