import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const LeadContactForm = ({ onSubmit }) => {
    const [formData, setFormData] = useState({
        email: '',
        message: '',
    });
    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
    const [sendStatus, setSendStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleCheckboxChange = (e) => {
        setIsPrivacyChecked(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isPrivacyChecked) {
            setSendStatus('Musíte súhlasiť so zásadami ochrany osobných údajov.');
            return;
        }

        setIsLoading(true);

        fetch('https://backend.lucasdesign.sk/wp-json/email/v1/send-email/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setSendStatus('Dakujem za váš email, čoskoro vás budem kontaktovať.');
                setIsLoading(false);
                if (onSubmit) {
                    onSubmit();
                }
            })
            .catch(error => {
                console.error(error);
                setSendStatus('Pri odosielaní e-mailu sa vyskytla chyba.');
                setIsLoading(false);
            });
    };

    return (
        <form className='lead-contact-form' onSubmit={handleSubmit}>
            <label className='email-and-button'>
                <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder='Email' required />
                <button type="submit" className="btn">
                    <div className="btn-inner">
                        <div className="btn-inner-text">{isLoading ? 'Odosiela sa...' : 'Odoslať'}</div>
                        <div className="btn-inner-text-hover">{isLoading ? 'Odosiela sa...' : 'Odoslať'}</div>
                    </div>
                </button>
            </label>

            <label>
                <input type="checkbox" checked={isPrivacyChecked} onChange={handleCheckboxChange} required />
                Súhlasím s <Link target='_blank' to={"/ochrana-osobnych-udajov"}>pravidlami ochrany súkromia</Link>
            </label>
            <br />
            <b className='sendStatus'>{sendStatus}</b>
        </form>
    );
};

export default LeadContactForm;
