import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';

import { getCLS, getFID, getLCP, getFCP, getTTFB } from 'web-vitals';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import PageNotFound from './pages/PageNotFound';
import BlogPost from './blog/BlogPost';
import Blog from './pages/Blog';
import Portfolio from './pages/Portfolio';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LeadModal from './components/LeadModal';


// Declare gtag function
function gtag() {
  window.dataLayer.push(arguments);
}

export default function App() {

  // Initialize GA4
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-BJ3TTZZ74F');
  }, []);

  // Function to send custom events to GA4
  const sendToAnalytics = ({ id, name, value }) => {
    gtag('event', name, {
      event_category: 'Web Vitals',
      event_label: id,
      value: Math.round(name === 'CLS' ? value * 1000 : value), // Convert to milliseconds
      non_interaction: true, // Make sure it doesn't affect bounce rate
    });
  };

  // Report Core Web Vitals to GA4
  useEffect(() => {
    getCLS(sendToAnalytics);
    getFID(sendToAnalytics);
    getLCP(sendToAnalytics);
    getFCP(sendToAnalytics);
    getTTFB(sendToAnalytics);
  }, []); // Ensure this effect runs only once on mount

  return (
    <HelmetProvider>
      <Router>
        <>
          <Helmet>
            <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="bacae79e-de49-461b-ba9b-06e936d1c810" data-blockingmode="auto" type="text/javascript"></script>
            <script async data-cookieconsent="marketing" src="https://www.googletagmanager.com/gtag/js?id=G-BJ3TTZZ74F"></script>
          </Helmet>
          <div id='main-wrapper' className='container'>
            <Header />
            <main>
              <LeadModal />
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path="*" element={<PageNotFound />} status={404} />
                <Route path='/portfolio' element={<Portfolio />} />
                <Route path='/ochrana-osobnych-udajov' element={<PrivacyPolicy />} />
                <Route path='/kontakt' element={<Contact />} />
                <Route path='/blog' element={<Blog />} />
                <Route path='/blog/' element={<Blog />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
              </Routes>
            </main>
          </div>
          <Footer />
        </>
      </Router>
    </HelmetProvider>
  )
}