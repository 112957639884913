import React, { useEffect } from 'react';
import LeadContactForm from './LeadContactForm';

const LeadModal = () => {
    const [show, setShow] = React.useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShow(true);
      }, 6000);
      return () => clearTimeout(timer);
    }, []);
  
    const handleClose = () => setShow(false);
  
    return (
      <>
        <div className={`modal lead-modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} id="leadModal" tabIndex="-1" role="dialog" aria-labelledby="leadModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="leadModalLabel">Získajte Bezplatnú Konzultáciu</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Nechajte mi svoj email a <b>získajte bezplatnú konzultáciu!</b> Pomôžem vám s vaším webom.</p>
                <LeadContactForm />
              </div>
              <div className="modal-footer">
              </div>
            </div>
          </div>
        </div>
        {show && <div className="modal-backdrop fade show" />}
      </>
    );
};

export default LeadModal;
