import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import '../assets/css/fonts.css';
import '../assets/css/bootstrap/bootstrap.min.css';
import '../assets/css/style.scss';
import ContactSection from '../components/ContactSection';
import LogoCarousel from '../components/LogoCarousel';
import PortfolioSection from '../components/PortfolioSection';
import { Fade } from "react-awesome-reveal";
const Blog = lazy(() => import('../blog/BlogList'));

export default function Home() {


  return (
    <>
      <Helmet>
        <title>LucasDesign</title>
        <meta name="title" content="LucasDesign" />
        <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
        <meta name="keywords" content="web developer,developer,website,web,custom" />
        <meta name="robots" content="index, follow" />
        <meta name="description" content="Vaša Cesta k Digitálnemu Rozkvitu. Vytváram Web pre Váš Úspech." />
      </Helmet>
      <section className="section-home-hero">
        <div className="container-inner">
          <div className="row">
            <div className="col-12">
              <Fade triggerOnce duration={1000} direction="up">
                <h1 className='mb-4'>Front-end Developer, WordPress a WooCommerce developer.</h1>
                <p>Som skúsený webový vývojár so špecializáciou na WordPress a súčasné webové technológie, ktorý vytvára webstránky, ktoré nielenže splnia, ale aj prekonajú vaše očakávania. Spojím vašu víziu so svojimi technickými schopnosťami a vytvorím vizuálne atraktívne, plne funkčné a na mieru prispôsobené webové stránky.
                  <br />V súčasnosti pracujem ako <a href='#services'>web developer</a> pre spoločnosť <a href="https://ryvenia.sk/" target="_blank" rel="noopener noreferrer">Ryvenia</a> a zároveň pre <a href="https://barneystudio.com/" target="_blank" rel="noopener noreferrer">Barney Studio</a>.</p>
              </Fade>
            </div>
          </div>
        </div>

        <Fade cascade triggerOnce direction="up">
          <LogoCarousel />
        </Fade>

      </section>

      <PortfolioSection postcount="4" showAllButton="true" />

      <section id="services" className="section-services">
        <div className="container-inner">
          <div className="row gx-7">
            <div className="col-12 col-lg-6 left">
              <div className="left-inner">
                <span>kto som & čo robím?</span>
                <h2>Osoba s precíznou organizáciou a odhodlaním nájsť riešenie pre každý problém.</h2>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right">
              <div className="vstack gap-3">
                <div className="services-card-wrapper">
                  <img src="./img/service-2.svg" height="60" width="60" alt="Web development" />
                  <h3>Tvorba firemnej webstránky a e-shopu</h3>
                  <p>Moja špecializácia je vytváranie profesionálnych webových stránok, ktoré posunú vaše online podnikanie na nový level. Spoločne môžeme vytvoriť atraktívny firemný web alebo e-shop, ktorý presne vyhovuje vašim potrebám a cieľom.</p>
                </div>
                <div className="services-card-wrapper">
                  <img src="./img/service-3.svg" height="60" width="60" alt="e-commerce" />
                  <h3>Postarám sa o váš web a e-shop</h3>
                  <p>Okrem vývoja sa starám aj o údržbu webových stránok a e-shopov. To zahŕňa aktualizácie pluginov, zabezpečenie, optimalizáciu, import a export produktov a ďalšie.</p>
                </div>
              </div>

              <a className="btn" href="mailto:info@lucasdesign.sk">
                <div className="btn-inner">
                  <div className="btn-inner-text">
                    Kontaktujte ma</div>
                  <div className="btn-inner-text-hover">
                    Kontaktujte ma</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <ContactSection />

      <Suspense fallback={<div>Loading</div>}>
        <Blog />
      </Suspense>

    </>

  )
}